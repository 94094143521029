<template>
  <CForm ref="form" class="needs-validation">
    <CSelect
      description="Providers"
      placeholder="Select Provider"
      horizontal
      :options="marketPlaceProviders"
      :value.sync="data.provider"
      required
    />

    <CInput
      description="Free Text"
      type="text"
      horizontal
      v-model="data.freeText"
    />
      <Input type="file" @change="handleFileUpload($event)" :required="true"/>

  </CForm>
</template>

<script>
export default{
  name: "MarketPlaceForm",
  props:{
    validated: Boolean,
    params: Object,
    actionType: String,
  },
  watch:{
    params: function(val){
      this.data = {...val};
    },
    actionType:  function(val){
      if(val == 'create'){
        this.data = {...this.params};
      }
    },
    validated:  function(){
        if(!this.data.file){
            const toast = {
                msg: 'Dosya alanı zorunludur lütfen bir excel dosyası yükleyiniz',
                header: 'Hata',
                color: 'danger'
            }
            this.$store.commit('showToast', toast)
        }
    }
  },
  computed: {
    marketPlaceProviders(){
      let data = []
      this.$store.getters.marketPlaceProviders.map(provider => data.push({value: provider.id, label: provider.providerName }))
      return data
    } 
  },
  data: function(){
    return{
      data: this.params ? {...this.params} : {},

    }
  },
  methods: {
    handleFileUpload(item){
      this.data.file = item.target.files[0];
    },
  }
}
</script>